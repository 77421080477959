import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { theme } from "../theme/theme";

export const WorkList = ({ data: allWorks }) => {
  return (
    <Section className="ArticleList flex col container padding">
      <div className="List flex col">
        <div
          css={`
            width: 100%;
            padding-bottom: 4px;
            span {
              font-size: 14px;
              font-weight: 300;
              display: inline-flex;
            }
            span:nth-of-type(1) {
              width: 30%;
            }
            span:nth-child(2) {
              width: 50%;
            }
            span:nth-child(3) {
              width: 20%;
              @media (max-width: 900px) {
                justify-content: flex-end;
              }
            }
          `}
        >
          <span>Client</span>
          <span>Type</span>
          <span>Year</span>
        </div>
        {allWorks.map((i) => {
          let tags = [];
          if (i.node.tag) {
            let arr = i.node.tag.split(/[,]+/);
            tags = arr.map((string) => {
              return string.trim();
            });
          }

          return (
            <Link
              className="Item flex align-center"
              to={`/work/${i.node.slug}`}
            >
              <h3 data-content={i.node.client}>{i.node.client}</h3>
              <span className="tag">{tags.join(", ")}</span>
              <span className="year inline-flex align-center">
                {i.node.year}{" "}
                <svg
                  className="ml-auto"
                  width="27"
                  height="30"
                  viewBox="0 0 27 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0.857895L10.6041 2.25382L22.3175 13.9672L0.113126 13.5638L0.0774941 15.5373L22.4055 15.9448L10.6041 27.7462L12 29.1422L26.1421 15L12 0.857895Z"
                    fill="black"
                  />
                </svg>
              </span>
            </Link>
          );
        })}
      </div>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  .List {
    display: flex;
    padding-top: 45px;
    padding-bottom: 40px;
  }
  .Item {
    border-top: 1px solid #ebebeb;
    transition: all 0.2s;
    min-height: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    h3 {
      padding-right: 15px;
      width: 30%;
    }
    .tag {
      width: 50%;
      padding-right: 15px;
    }
    .year {
      width: 20%;
      @media (max-width: 900px) {
        justify-content: end;
        svg {
          display: none;
        }
      }
    }
    h3,
    span {
      font-size: 25px;
      font-weight: 500;
      @media (max-width: 900px) {
        font-size: 18px;
      }
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
    &:hover {
      background: black;
      h3,
      span {
        color: #fff;
      }
      .year svg path {
        fill: #fff;
      }
    }
  }
`;
